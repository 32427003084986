//@author travis

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { DropDownItem } from "../../pojo/DropDownItem";
import { S25ItemI } from "../../pojo/S25ItemI";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-contacts-editable")
@Component({
    selector: "s25-ng-contacts-editable",
    template: ` <div *ngIf="this.init" class="notification-policy--wrapper">
        <div
            *ngFor="let contact of this.modelBean; let i = index"
            class="notification-policy--flex c-margin-bottom--quarter"
        >
            <div>
                <span
                    (click)="remove(contact)"
                    (enter)="remove(contact)"
                    tabindex="0"
                    class="ngCpointer ng-scope c-margin-right--half"
                    role="button"
                >
                    <svg class="c-svgIcon">
                        <title>Remove {{ contact?.itemName }}</title>
                        <use xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#close-x"></use>
                    </svg>
                </span>
                <span>
                    <s25-item-generic [modelBean]="contact" [includeTypeIcon]="true"></s25-item-generic>
                </span>
            </div>
            <span class="ng-binding notification-policy--dropdown">
                <!--<s25-generic-dropdown [items]="this.dropDownItems" [(chosen)]="contact.property" [searchEnabled]="false" (chosenChange)="setType($event, i)"></s25-generic-dropdown>-->
                <s25-ng-dropdown-search-criteria
                    [type]="'organizationRoles'"
                    [(chosen)]="contact.property"
                    (chosenChange)="setType($event, i)"
                ></s25-ng-dropdown-search-criteria>
            </span>
            <span class="ngCpointer ng-scope c-margin-right--half primary-contact--radio" *ngIf="primaryContact">
                <label for="primaryContact_{{ contact.itemId }}">
                    <input
                        type="radio"
                        id="primaryContact_{{ contact.itemId }}"
                        name="primary"
                        (click)="setPrimary(contact)"
                        (enter)="setPrimary(contact)"
                    />Primary Contact
                </label>
            </span>
        </div>
        <div class="c-margin-top--single">
            <span class="ngBold">Add Contact</span>
            <s25-contact-dropdown
                (chosenChange)="addContact($event)"
                [r25UserOnly]="this.r25UserOnly"
                [resetSelectedOnCleanup]="true"
                (onSelect)="(this.selectContact)"
            ></s25-contact-dropdown>
            <!--<s25-ng-dropdown-search-criteria [type]="'contacts'" [(chosen)]="this.newContact" (chosenChange)="addContact($event)" [resetSelectedOnCleanup]="true"></s25-ng-dropdown-search-criteria>-->
        </div>
    </div>`,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})

/**
 * Component for picking a number of contacts with an additional property.
 */
export class S25ContactsEditableComponent implements OnInit {
    @Input() itemTypeId: number = 3;
    @Input() modelBean: S25ItemI[] = []; //S25ItemI + property: {itemId: itemName: }
    @Input() dropDownItems?: DropDownItem[]; //items to display in the dropdown OR some sort of code for a generic dropdown?
    @Input() dropDownLabel?: string;
    @Input() r25UserOnly?: boolean = true;
    @Input() primaryContact: boolean; //Show or hide primary contact chooser
    @Output() modelBeanChange = new EventEmitter<S25ItemI[]>();
    newContact: DropDownItem = null;
    init: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    ngOnInit(): void {
        this.modelBean = S25Util.array.forceArray(this.modelBean);
        this.init = true;
        this.cd.detectChanges();
    }

    addContact(event: any) {
        !S25Util.array.isIn(this.modelBean, "itemId", event.itemId) && this.modelBean.push(event);
        this.modelBeanChange.emit(this.modelBean);
    }

    remove(item: any) {
        S25Util.array.inplaceRemoveByProp(this.modelBean, "itemId", item.itemId);
        this.cd.detectChanges();
        this.modelBeanChange.emit(this.modelBean);
    }

    setPrimary(item: any) {
        let i = S25Util.array.findByProp(this.modelBean, "isPrimary", true);
        if (i > -1) {
            this.modelBean[i].isPrimary = false;
        }
        item.isPrimary = true;
        this.modelBeanChange.emit(this.modelBean);
    }

    setType(event: any, i: number) {}
}
