//@author: mandy

import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from "@angular/core";
import { AddressI } from "../../pojo/AddressI";
import { TypeManagerDecorator } from "../../main/type.map.service";

const WORK_ADDRESS = 3;
const HOME_ADDRESS = 4;
@TypeManagerDecorator("s25-ng-address")
@Component({
    selector: "s25-ng-address",
    template: `
        <div class="editable-address" [ngClass]="{ 'read-only': readOnly }">
            <div>
                <label for="street-{{ index }}"> Street Address </label>
                <input
                    *ngIf="!readOnly"
                    type="text"
                    id="street-{{ index }}"
                    [(ngModel)]="this.model.street"
                    class="street c-input ngBlock"
                />
                <span *ngIf="readOnly">{{ this.model.street }}</span>
            </div>

            <div>
                <label for="city-{{ index }}"> City </label>
                <input
                    *ngIf="!readOnly"
                    type="text"
                    id="city-{{ index }}"
                    [(ngModel)]="this.model.city"
                    class="city c-input ngBlock"
                />
                <span *ngIf="readOnly">{{ this.model.city }}</span>
            </div>

            <div>
                <label for="state-{{ index }}"> State / Province </label>
                <input
                    *ngIf="!readOnly"
                    type="text"
                    id="state-{{ index }}"
                    [(ngModel)]="this.model.state"
                    class="state c-input ngBlock"
                />
                <span *ngIf="readOnly">{{ this.model.state }}</span>
            </div>

            <div>
                <label for="zip-{{ index }}"> Zip </label>
                <input
                    *ngIf="!readOnly"
                    type="text"
                    id="zip-{{ index }}"
                    [(ngModel)]="this.model.zip"
                    class="zip c-input ngBlock"
                />
                <span *ngIf="readOnly">{{ this.model.zip }}</span>
            </div>

            <div>
                <label for="country-{{ index }}"> Country </label>
                <input
                    *ngIf="!readOnly"
                    type="text"
                    id="country-{{ index }}"
                    [(ngModel)]="this.model.country"
                    class="country c-input ngBlock"
                />
                <span *ngIf="readOnly">{{ this.model.country }}</span>
            </div>

            <div *ngIf="this.hasPhone">
                <label for="phone-{{ index }}"> Phone </label>
                <input
                    *ngIf="!readOnly"
                    type="text"
                    id="phone-{{ index }}"
                    [(ngModel)]="this.model.phone"
                    class="phone c-input ngBlock"
                />
                <span *ngIf="readOnly">{{ this.model.phone }}</span>
            </div>

            <div *ngIf="this.hasPhone">
                <label for="fax-{{ index }}"> Fax </label>
                <input
                    *ngIf="!readOnly"
                    type="text"
                    id="fax-{{ index }}"
                    [(ngModel)]="this.model.fax"
                    class="fax c-input ngBlock"
                />
                <span *ngIf="readOnly">{{ this.model.fax }}</span>
            </div>

            <div *ngIf="this.showEmail" id="showEmail">
                <label for="email-{{ index }}"> Email </label>
                <span class="required" *ngIf="this.emailRequired">*</span>
                <input
                    type="email"
                    id="email-{{ index }}"
                    [(ngModel)]="this.model.email"
                    class="email c-input ngBlock"
                    [attr.required]="this.emailRequired"
                />
            </div>
        </div>
    `,
    styles: `
        .street {
            min-width: 350px;
        }

        .email {
            min-width: 350px;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25AddressComponent implements OnInit {
    @Input() model: AddressI = undefined;
    @Input() readOnly: boolean;
    @Input() hasPhone?: boolean = true;
    showEmail: boolean = false;
    emailRequired: boolean = false;

    private static count = 0;
    index: number;

    constructor(private elementRef: ElementRef) {
        this.index = S25AddressComponent.count;
        S25AddressComponent.count++;
    }

    ngOnInit() {
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns

        if (this.model.addressType === WORK_ADDRESS || this.model.addressType === HOME_ADDRESS) {
            this.showEmail = true;
            this.emailRequired = this.model.addressType === WORK_ADDRESS;
        }
    }
}
